import React from "react"
import styled from "styled-components"

import Link from "components/Link"
import { FONT } from "const"

const Container = styled.div`
  margin-bottom: 24px;
  font-size: 15px;
  line-height: 28px;
  color: var(--text-color);

  :before {
    content: "";
    display: block;
    background-color: var(--found-bug-border);
    height: 1px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 12px;
  }

  a {
    color: var(--link-color);
    font-family: ${FONT.PRIMARY};
    font-size: 15px;
    line-height: 28px;
    font-weight: 600;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`

console.log(
  "%c Found a bug? Email us at support+docs@dasha.ai!",
  "background-color: #ffdd2d; color: #333;"
)

const FoundBug = () => (
  <Container>
    <b>Found a mistake?</b> <Link href="mailto:support+docs@dasha.ai">Email us</Link>, and we'll send you a
    free t-shirt!
  </Container>
)

export default FoundBug
